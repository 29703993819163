/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import config from '../configures/config';

import Router from './Router';
import { getPermissions, refreshTokenAuth } from './auth/endpoints';

const OEMApp = () => {
  const navigate = useNavigate();
  const {
    resetUserValues,
    setUser,
    processSignIn,
    setAuthLoader
  } = useStoreActions(actions => actions.authentication);
  const { setIsRedirecting } = useStoreActions(actions => actions.general);
  const { setUserData } = useStoreActions(actions => actions.user);
  const { theme } = useStoreState(state => state.theme);

  useEffect(() => {
    const token = localStorage.getItem('Authorization');
    if (token) {
      config({
        name: jwt(token)?.user?.Email,
        fullName: jwt(token)?.user?.Email
      });
    }

    window.addEventListener('buckets', () => {
      navigate('buckets');
    });

    window.addEventListener('storage', () => {
      const token = localStorage.getItem('Authorization');
      if (!token) {
        const refreshToken = localStorage.getItem('RefreshToken');
        if (refreshToken) {
          refreshTokenAuth(refreshToken).then(([res]) => {
            if (res.success && res.data) {
              localStorage.setItem('Authorization', res.data.AccessToken);
              processSignIn(res.data);
              getPermissions().then(([result]) => {
                localStorage.setItem(
                  'Permissions',
                  JSON.stringify(result.data)
                );
                navigate(0);
              });
            } else {
              localStorage.clear();
              localStorage.setItem('refreshTokenExpired', 'true');
              resetUserValues();
              navigate('login', { replace: true });
            }
          });
        } else {
          window.dispatchEvent(new Event('logout'));
        }
      }
    });

    window.addEventListener('set_user_data', () => {
      const token = localStorage.getItem('Authorization');
      const decodedToken = jwt(token); // decode your token here

      setUserData(decodedToken.user);
      setUser(decodedToken.user);
    });

    window.addEventListener('logout', () => {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const redirectTo = theme.logOutUrl.startsWith('http')
        ? theme.logOutUrl
        : 'https://'.concat(theme.logOutUrl);
      const isSsoUser = JSON.parse(localStorage.getItem('userIsSso'));
      const isSamlSsoWithoutSignOut = JSON.parse(
        localStorage.getItem('IsSamlSsoWithoutSignOut')
      );

      localStorage.removeItem('Permissions');
      localStorage.removeItem('RefreshToken');
      localStorage.removeItem('Authorization');
      localStorage.removeItem('firstAndLastName');
      localStorage.removeItem('BANNER_DISMISSED');
      setIsRedirecting(true);
      resetUserValues();
      if (isSsoUser) {
        if (isSamlSsoWithoutSignOut) {
          if (!localStorage.getItem('accountDeActive'))
            localStorage.removeItem('userIsSso');
          localStorage.removeItem('isSamlSsoWithoutSignOut');
        } else {
          if (!localStorage.getItem('accountDeActive'))
            localStorage.removeItem('userIsSso');
          localStorage.removeItem('isSamlSsoWithoutSignOut');
          window.location.href = `${process.env.REACT_APP_SSO_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&federated&returnTo=${baseUrl}${process.env.REACT_APP_SSO_REDIRECT_URL}`;
        }
      }
      navigate('/login');
      if (!token && localStorage.getItem('LogoutUrl')) {
        localStorage.removeItem('accountDeActive');
        localStorage.removeItem('LogoutUrl');
        navigate('/login');
      }
      if (
        theme.logoutRedirect === 'OEM_PARTNER_CUSTOM_URL' &&
        theme.logOutUrl.length > 0
      ) {
        localStorage.setItem('LogoutUrl', redirectTo);
        window.location.href = redirectTo;
      } else {
        navigate('login', { replace: true });
        setAuthLoader(false);
      }
      setTimeout(() => {
        setIsRedirecting(false);
      }, 1200);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Router />;
};

export default OEMApp;

/* eslint-disable no-param-reassign */
export function formatStorageValue(value, dataCard) {
  let unit = '';
  let formattedValue = '--';
  value *= 1024 * 1024 * 1024;

  if (
    [
      'totalStorage',
      'totalCapacity',
      'usedCapacity',
      'remainingCapacity'
    ].includes(dataCard)
  ) {
    value *= 1024;
  }

  if (value === 0) {
    unit = 'B';
    formattedValue = 0;
  } else if (value >= 1) {
    unit = 'B';
    formattedValue = value.toFixed(0);
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'KB';
    formattedValue = Math.round(value).toString();
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'MB';
    formattedValue = Math.round(value).toString();
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'GB';
    formattedValue = Math.round(value).toString();
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'TB';
    formattedValue = value.toFixed(3);
  }

  if (value >= 1024) {
    value /= 1024;
    unit = 'PB';
    formattedValue = value.toFixed(3);
  }

  if (value < 1 && unit === '') {
    unit = 'TB'; // Default to TB for values less than 1 in this case
    formattedValue = value.toFixed(3); // Properly format values below 1 TB
  }

  return `${formattedValue} ${unit}`;
}

import i18n from '../i18n';

export function saveDismissState() {
  const key = 'BANNER_DISMISSED';
  // eslint-disable-next-line no-restricted-globals
  localStorage.setItem(key, 'true');
}

export function isBannerDismissed() {
  const key = 'BANNER_DISMISSED';
  // eslint-disable-next-line no-restricted-globals
  return localStorage.getItem(key) === 'true';
}

export function shouldShowBanner() {
  return !isBannerDismissed();
}

export function handleDismiss() {
  saveDismissState();
  return false;
}

export const calculateBannerDetails = data => {
  const percentageUsed =
    data.usedQuantity && data.totalCapacity
      ? Math.round((data.usedQuantity / data.totalCapacity) * 100)
      : 0;

  // eslint-disable-next-line no-restricted-globals
  const hasShownQuotaExceededModal = localStorage.getItem(
    'hasShownQuotaExceededModal'
  );

  if (hasShownQuotaExceededModal) {
    return {
      isBannerVisible: true,
      isCloseIconVisible: true,
      bannerType: 'warning',
      bannerText: i18n.t('cannotUploadDataBannerText')
    };
  }
  if (data.quotaType === 'Soft quota' && percentageUsed >= 50) {
    return {
      isBannerVisible: true,
      isCloseIconVisible: percentageUsed < 90,
      bannerType: percentageUsed >= 90 ? 'error' : 'warning',
      bannerText: i18n.t('storageCapacityBannerText', { percentageUsed })
    };
  } else {
    if (percentageUsed === 100) {
      return {
        isBannerVisible: true,
        isCloseIconVisible: true,
        bannerType: 'warning',
        bannerText: i18n.t('cannotUploadDataBannerText')
      };
    }
    if (percentageUsed >= 90) {
      return {
        isBannerVisible: true,
        isCloseIconVisible: false,
        bannerType: 'error',
        bannerText: i18n.t('storageDangerText', { percentageUsed })
      };
    } else if (percentageUsed >= 50) {
      return {
        isBannerVisible: true,
        isCloseIconVisible: true,
        bannerType: 'warning',
        bannerText: i18n.t('storageWarningTextNoTime', { percentageUsed })
      };
    } else {
      return { isBannerVisible: false };
    }
  }
};
